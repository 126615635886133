.page_wrap {
	background-color: #ea014f;
	height: 100vh;
	width: 100vw;
}

.main {
	display: flex;
	justify-content: space-between;
	height: 100%;
	width: 100%;
}

/*CENTER*/
.center_wrap, .side_wrap {
	height: 100%;
	flex-basis: 354px;
}

.center_wrap {
	flex-grow: 1;
	min-width: 600px;
}

.center_main {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.center_top_block {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.center_title_wrap {
	margin-top: 1rem;
	color: #ffffff;
}

.center_title_wrap, .center_title_wrap p {
	font-size: 32px;
	font-weight: 700;
}

.center_title_animated_span {
	color: #f7be11;
	display: inline-flex;
	overflow: hidden;
}

.logo_wrap {
	padding-top: 2rem;
}

.logo_wrap img {
}

.social_image_block {
	display: flex;
}

.social_image_wrap {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.social_image_wrap img {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	max-width: 145px;
}

/*SIDE*/
.side_wrap {
	position: relative;
}

.side_wrap img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*BOTTOM*/
.bottom_wrap {
	width: 100%;
}

.bottom_wrap img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

@media all and (max-width: 1049px) {
	.side_wrap {
		height: 200px;
		width: 200px;
		flex-basis: 200px;
		flex-shrink: 0;
	}

	.side_wrap img {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		height: auto;
	}

	.center_wrap {
		flex-shrink: 1;
		min-width: unset;
	}
}

@media all and (max-width: 950px) {
	.side_wrap {
		display: none;
	}
}

@media all and (max-width: 550px) {
	.center_title_wrap, .center_title_wrap p {
		font-size: 18px;
	}

	.logo_wrap {
		max-width: 185px;
	}

	.social_image_wrap img {
		max-width: 100px;
	}
}

@media all and (max-width: 380px) {
	.center_title_wrap, .center_title_wrap p {
		font-size: 16px;
	}

	.social_image_wrap img {
		max-width: 90px;
	}
}

@media all and (max-height: 600px) {
	.bottom_wrap {
		display: none;
	}
}
